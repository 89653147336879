<template>
  <div>
    <user />
     <!--<a-tabs default-active-key="1" @change="callback">

      <a-tab-pane key="1" tab="用户签章">
        <user />
      </a-tab-pane>
      <a-tab-pane key="2" tab="部门签章">
        <department />
      </a-tab-pane>
    </a-tabs>-->
  </div>
</template>
<script>
import user from './user.vue'
// import department from './department.vue'
export default {
  components: {
    user
    //, department
  },
  data () {
    return {}
  },
  methods: {
    callback (key) {
      console.log(key)
    }
  }
}
</script>
